<template>
  <van-popup v-model="visible" round position="bottom" @close="onClose">
    <div class="rate-popup-header">
      <div class="rate-popup-title">
        服务评价
      </div>
      <div class="close-btn" @click="onClose">关闭</div>
    </div>
    <div class="popup-box">
      <div class="rate-info">
        <div class="avatar">
          <img src="~@/assets/images/user/avatar_2.svg" class="fit-img" alt="">
        </div>
        <div class="info">
          <div class="title">
            <div class="username">{{ info.manager_username }}</div>
            <div class="user-tag flex-center">服务工程师</div>
          </div>
          <div class="phone">{{ info.manager_mobile }}</div>
        </div>
        <div class="desc">评价已对工程师匿名</div>
      </div>
      <div class="rate-main">
        <div class="rate-box">
          <van-rate
            v-model="score"
            size="8vw"
            gutter="4.4vw"
            void-color="#F2F2F2"
            :color="colors[score-1]"
            void-icon="star"
            @change="changeRate"
          />
        </div>
        <div class="rate-desc">{{ description[score-1] }}</div>
        <div class="rate-list-box">
          <div class="rate-list">
            <div class="rate-item flex-center van-hairline--surround" :class="{'checked': item.checked }" v-for="(item, index) in rateList[score-1]" :key="index" @click="item.checked = !item.checked">
              {{ item.label }}
            </div>
          </div>
        </div>
        <div class="rate-textarea">
          <textarea placeholder="点击这里快速输入评价…" class="textarea" v-model="content" maxlength="50"></textarea>
          <div class="text-length">{{ content.length }}/50</div>
        </div>
      </div>
      <transition name="van-fade">
        <div class="rate-result" v-if="hasResult">
          <img src="~@/assets/images/icon/icon_check.svg" alt="">
          <span>提交成功</span>
        </div>
      </transition>
    </div>
    <div class="rate-popup-ft" v-if="!hasResult">
      <van-button class="popup-btn" type="info" @click="onSubmit" :loading="loading">确定</van-button>
    </div>
    <div class="rate-popup-ft" v-if="hasResult">
      <van-button class="popup-btn" type="info" @click="onClose">返回</van-button>
    </div>
  </van-popup>
</template>

<script>
  import { Popup, Rate, Button, Toast } from 'vant'
  import { rateOrder } from '@/api/order'

  export default {
    components: {
      [Popup.name]: Popup,
      [Rate.name]: Rate,
      [Button.name]: Button
    },
    props: {
      visible: {
        type: Boolean,
        value: false
      },
      id: {

      },
      info: {
        
      }
    },
    watch: {
      id() {
        this.score = 5
        this.content = ''
        this.hasResult = false
        this.rateList.forEach(el => {
          el.map(item => {
            item.checked = false
          })
        })
      }
    },
    data() {
      return {
        score: 5,
        colors: ['#A3A3A3', '#FF954B', '#FF954B', '#FF954B', '#186AFF'],
        description: [
          '“非常不满意，各方面都比较差”',
          '“不满意，比较差”',
          '“一般，还需改善”',
          '“比较满意，仍可改善”',
          '“非常满意，无可挑剔”'
        ],
        checkedIndex: -1,
        rateList: [ 
          [
        {label: '未提前沟通上门时间', checked: false},
        {label: '催很久才上门', checked: false},
        {label: '处理进度慢', checked: false},
        {label: '沟通比较随意', checked: false},
        {label: '欠缺耐心', checked: false},
        {label: '态度很差', checked: false},
        {label: '很不专业', checked: false},
        {label: '不会使用产品', checked: false},
        {label: '无法一次解决问题', checked: false},
        {label: '没穿鞋套', checked: false},
        {label: '物品未归位', checked: false},
        {label: '干活很粗糙', checked: false},
        {label: '费用未提前告知', checked: false},
        {label: '不按标准收费', checked: false},
        {label: '无收费单', checked: false},
      ],
      [
        {label: '未提前沟通上门时间', checked: false},
        {label: '催很久才上门', checked: false},
        {label: '处理进度慢', checked: false},
        {label: '沟通比较随意', checked: false},
        {label: '欠缺耐心', checked: false},
        {label: '态度很差', checked: false},
        {label: '很不专业', checked: false},
        {label: '不会使用产品', checked: false},
        {label: '无法一次解决问题', checked: false},
        {label: '没穿鞋套', checked: false},
        {label: '物品未归位', checked: false},
        {label: '干活很粗糙', checked: false},
        {label: '费用未提前告知', checked: false},
        {label: '不按标准收费', checked: false},
        {label: '无收费单', checked: false},
      ],
      [
        {label: '未提前沟通上门时间', checked: false},
        {label: '催很久才上门', checked: false},
        {label: '处理进度慢', checked: false},
        {label: '沟通比较随意', checked: false},
        {label: '欠缺耐心', checked: false},
        {label: '态度很差', checked: false},
        {label: '很不专业', checked: false},
        {label: '不会使用产品', checked: false},
        {label: '无法一次解决问题', checked: false},
        {label: '没穿鞋套', checked: false},
        {label: '物品未归位', checked: false},
        {label: '干活很粗糙', checked: false},
        {label: '费用未提前告知', checked: false},
        {label: '不按标准收费', checked: false},
        {label: '无收费单', checked: false},
      ],
      [
        {label: '未提前沟通上门时间', checked: false},
        {label: '催很久才上门', checked: false},
        {label: '处理进度慢', checked: false},
        {label: '沟通比较随意', checked: false},
        {label: '欠缺耐心', checked: false},
        {label: '态度很差', checked: false},
        {label: '很不专业', checked: false},
        {label: '不会使用产品', checked: false},
        {label: '无法一次解决问题', checked: false},
        {label: '没穿鞋套', checked: false},
        {label: '物品未归位', checked: false},
        {label: '干活很粗糙', checked: false},
        {label: '费用未提前告知', checked: false},
        {label: '不按标准收费', checked: false},
        {label: '无收费单', checked: false},
      ],
      [
        {label: '提前沟通时间', checked: false},
        {label: '准时上门', checked: false},
        {label: '快速解决', checked: false},
        {label: '沟通很礼貌', checked: false},
        {label: '很耐心', checked: false},
        {label: '态度很好', checked: false},
        {label: '师傅很专业', checked: false},
        {label: '指导使用', checked: false},
        {label: '讲解很清楚', checked: false},
        {label: '穿鞋套上门', checked: false},
        {label: '卫生都清理干净', checked: false},
        {label: '无尘安装', checked: false},
        {label: '费用提前告知', checked: false},
        {label: '出示收费标准', checked: false},
        {label: '留下收费单据', checked: false},
      ]
        ],
        content: '',
        hasResult: false,
        loading: false
      }
    },
    methods: {
      onClose() {
        this.$emit('close', true)
      },
      changeRate(e) {
        const index = e-1
        this.rateList[index].forEach(el => {
          el.checked = false
        })
      },
      onSubmit() {
        if (this.loading == true) {
          return
        }
        const list = [] 
        this.rateList[this.score-1].forEach(el => {
          if (el.checked) {
            list.push(el.label)
          }
        })
        const params = {
          id: this.id,
          score: this.score, 
          choose_info: list.join(','),
          remark: this.content,
        }
        this.loading = true
        rateOrder(params).then(() => {
          Toast.success('评价成功')
          this.loading = false
          this.hasResult = true
          this.$emit('submit', true)
        }).catch(() => {
          this.loading = false
        })
      }
    },
  }
</script>

<style lang="less" scoped>
  .popup-box {
    position: relative;
    .rate-result {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 20;
      background: #FFFFFF;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      img {
        width: 17.3333vw;
        height: 17.3333vw;
      }
      span {
        font-size: 4.8vw;
        color: #333333;
        text-align: center;
        margin-top: 8vw;
      }
    }
  }
  .rate-popup-header {
    position: relative;
    height: 17.8667vw;
    display: flex;
    align-items: center;
    justify-content: center;
    .rate-popup-title {
      font-weight: bold;
      font-size: 4.5333vw;
      color: #1C1F20;
    }
    .close-btn {
      padding: 2.6667vw;
      position: absolute;
      right: 4vw;
      top: 4vw;
      font-size: 3.7333vw;
      color: #9C9DAA;
      line-height: 1.1;
      z-index: 2;
    }
  }
  .rate-popup-ft {
    padding: 4vw 4vw calc(4vw + constant(safe-area-inset-bottom));
    padding: 4vw 4vw calc(4vw + env(safe-area-inset-bottom));
    .popup-btn {
      width: 100%;
      height: 12vw;
      font-size: 4vw
    }
  }
  .rate-info {
    display: flex;
    align-items: center;
    margin: 0 6.6667vw;
    .avatar {
      width: 13.8667vw;
      height: 13.8667vw;
      border-radius: 50%;
      overflow: hidden;
      img {
        border-radius: 50%;
      }
    }
    .info {
      flex: 1;
      width: 0;
      padding: 0 3.4667vw;
    }
    .desc {
      font-size: 2.9333vw;
      color: #9C9DAA;
    }
    .title {
      display: flex;
      align-items: center;
      .username {
        font-weight: bold;
        font-size: 4vw;
        color: #030303;
        line-height: 1.1;
      }
      .user-tag {
        height: 4.2667vw;
        border-radius: 0.4vw;
        font-size: 2.9333vw;
        color: #FFFFFF;
        padding: 0 1.3333vw;
        background: #186AFF;
        margin-left: 1.3333vw;
      }
    }
    .phone {
      font-size: 3.4667vw;
      color: #9C9DAA;
      line-height: 1.1;
      margin-top: 2.5333vw;
    }
  }
  .rate-box {
    display: flex;
    justify-content: center;
    margin-top: 8vw;
  }
  .rate-desc {
    font-size: 3.7333vw;
    color: #4C4C57;
    text-align: center;
    line-height: 1.1;
    margin-top: 6.4vw;
  } 
  .rate-list-box {
    max-height: 30vw;
    overflow-y: auto;
    margin: 7.4667vw 0 0;
  }
  .rate-list {
    margin: 0 4.6667vw 0;
    display: flex;
    flex-wrap: wrap;
    .rate-item {
      margin: 3.2vw 2vw 0;
      min-width: calc(33.33% - 4vw);
      padding: 0 2.6667vw;
      height: 8vw;
      font-size: 3.2vw;
      color: #1C1F20;
      border-radius: 1.0667vw;
      &:active {
        background-color: rgba(0,0,0,0.03);
      }
      &::after {
        border-radius: 2.1333vw;
        border-color: #979797
      }
      &.checked {
        background-color: #186AFF;
        color: #FFFFFF;
        &::after {
          display: none;
          border-color: #186AFF;
        }
      }
    }
  }
  .rate-textarea {
    padding: 4vw 6.6667vw 5.3333vw;
    position: relative;
    textarea { 
      width: 100%;
      height: 24.8vw;
      background: #F4F4F4;
      border-radius: 1.0667vw;
      padding: 4vw;
      border: 0 none;
      font-size: 3.2vw;
      line-height: 1.4;
      resize: none;
    }
    .text-length {
      font-size: 2.9333vw;
      line-height: 1.1;
      color: #D5D5D5;
      letter-spacing: 0.1333vw;
      position: absolute;
      right: 8vw;
      bottom: 9.3333vw;
      z-index: 4;
    }
  }
</style>