<template>
  <van-popup v-model="visible" round position="bottom" @close="onClose">
    <div class="popup-header">
      <div class="popup-title">
        选择服务时间
      </div>
      <div class="close-btn" @click="onClose">关闭</div>
    </div>
    <div class="calendar-box">
      <div class="calendar-header">
        <div class="cal-header-btn flex-center" @click="prevMonth">
          <van-icon name="arrow-left" size="3.0667vw" color="#9C9DAA"/>
        </div>
        <div class="cal-header-text">
          {{ monthStr }}
        </div>
        <div class="cal-header-btn flex-center" @click="nextMonth">
          <van-icon name="arrow" size="3.0667vw" color="#9C9DAA"/>
        </div>
      </div>
      <div class="calendar-body">
        <div class="cal-row head">
          <div class="cal-item" 
            v-for="item in weekList" :key="item">
            <span>{{ item }}</span>
          </div>
        </div>
        <div class="cal-row cal-body">
          <div class="cal-item"
            :class="{'disabled': item.disabled , 'active': item.year === curYear && item.month === curMonth && item.day === curDay }"
            v-for="(item, index) in dayList" :key="index"
            @click="changeDay(item)">
              <span v-if="item.day === -1"></span>
              <span v-else-if="item.year === today.year && item.month === today.month && item.day === today.day">今天</span>
              <span v-else>{{ item.day }}</span>
          </div>
        </div>
      </div>
      <div class="time-box">
        <div class="time-box--title">
          期望服务时间段<span>（可选择当前1个时段后的时间）</span>
        </div>
        <div class="time-tips info-tips">
          <van-icon name="info-o" color="#FF954B" size="3.2vw"/>
          <span style="font-size:3.333vw;color:#FF4B4B;font-weight:bold">温馨提示：具体服务时间以服务技师与您沟通的时间为准，请保持手机号码畅通。</span>
        </div>
        <div class="time-box--main">
          <div class="item flex-center" v-for="(item, index) in timeList" :key="index"
            @click="changeTime(item)"
            :class="{'active': curTime == item.label}">
            {{ item.label }}
          </div>
        </div>
      </div>
      
      <div class="popup-footer">
        <van-button class="popup-btn" type="info" @click="onSubmit">
          确定
        </van-button>
      </div>
    </div>
  </van-popup>
</template>

<script>
  import { Popup, Button, Icon, Toast, Dialog } from 'vant'
  import { updateOrderAppointTime } from '@/api/order'
  import dayjs from 'dayjs'

  export default {
    components: {
      [Popup.name]: Popup,
      [Button.name]: Button,
      [Icon.name]: Icon,
    },
    props: {
      visible: {
        type: Boolean
      },
      tid: {}
    },
    data() {
      return {
        monthStr: dayjs().format('YYYY.MM'),
        year: dayjs().year(),
        month: dayjs().month() + 1,
        day: dayjs().date(),
        curDay: '',
        curMonth: '',
        curYear: '',
        dayList: [],
        curTime: -1,
        today: {},
        timeList: [
          { label: '08:00-10:00', start: 8 },
          { label: '10:00-12:00', start: 10 },
          { label: '12:00-14:00', start: 12 },
          { label: '14:00-16:00', start: 14 },
          { label: '16:00-18:00', start: 16 }
        ],
        weekList: ['日', '一', '二', '三', '四', '五', '六']
      }
    },
    created() {
      this.today = {
        year: this.year,
        month: this.month,
        day: this.day,
        time: Date.now()
      }
      // const instanceDay = dayjs(new Date(this.year, this.month-1, this.day + this.timeInstance() + 1))
      // this.curDay = instanceDay.date()
      // this.curMonth = instanceDay.month() + 1
      // this.curYear = instanceDay.year()
      // this.loadMonthList()
    },
    methods: {
      timeInstance() {
        return new Date().getHours() >= 17 ? 1 : 0
      },
      loadMonthList() {
        const { year, month, today } = this
        const days = dayjs(`${year}-${month}`).daysInMonth()
        const emptyDays = dayjs(`${year}-${month}-1`).day()
        let list = [];
        for(let i=0; i<emptyDays; i++) {
          list.push({
            day: -1
          })
        }
        for(let i=1; i<=days; i++) {
          let disabled = true
          const beforeTimestamp = new Date(today.year, today.month-1, today.day + this.timeInstance()).getTime()
          const afterTimestamp = new Date(today.year, today.month-1, today.day + 30).getTime()
          if ( new Date(year, month-1, i).getTime() > beforeTimestamp
            && new Date(year, month-1, i).getTime() < afterTimestamp ) {
            disabled = false
          }
          list.push({
            year,
            month,
            day: i,
            disabled
          })
        }
        this.dayList = list
      },
      changeDay(date) {
        if (date.day === -1 || date.disabled === true) {
          return
        }
        this.curYear = date.year
        this.curMonth = date.month
        this.curDay = date.day
        this.curTime = -1
      },
      changeTime(time) {
        this.curTime = time.label
      },
      prevMonth() {
        if (this.month === 1) {
          this.month = 12
          this.year --
        } else {
          this.month --
        }
        this.monthStr = dayjs(`${this.year}-${this.month}`).format('YYYY.MM')
        this.loadMonthList()
      },
      nextMonth() {
        if (this.month === 12) {
          this.month = 1
          this.year ++
        } else {
          this.month ++
        }
        this.monthStr = dayjs(`${this.year}-${this.month}`).format('YYYY.MM')
        this.loadMonthList()
      },
      onClose() {
        this.$emit('close', true)
      },
      setTime(time) {
        console.log(time)
        const year = dayjs().year()
        const month = dayjs().month() + 1
        const day = dayjs().date()
        const instanceDay = dayjs(new Date(year, month-1, day + this.timeInstance() + 1))
        
        this.curDay = instanceDay.date()
        this.curMonth = instanceDay.month() + 1
        this.curYear = instanceDay.year()
        this.day = this.curDay
        this.month = this.curMonth
        this.year = this.curYear
        this.monthStr = dayjs(`${this.year}-${this.month}`).format('YYYY.MM')
        this.curTime = -1
        this.loadMonthList()
      },
      onSubmit() {
        if (this.curTime === -1) {
          Toast('请选择服务时间段')
          return
        }
        const item = this.dayList.find(el => el.day == this.curDay)
        if (item.disabled) {
          Dialog.alert({
            title: '提醒',
            message: '当前日期无法预约，请选择可选的日期',
            confirmButtonColor: '#186AFF'
          })
          return
        } 

        const result = {
          time: this.curTime,
          date: dayjs(`${this.curYear}-${this.curMonth}-${this.curDay}`).format('YYYY-MM-DD'),
        }
        
        const params = {
          appoint_time: result.date + ' ' + result.time,
          id: this.tid
        }

        const toast = Toast.loading({
          message: '加载中...',
          forbidClick: true,
        })
        updateOrderAppointTime(params).then(() => {
          toast.clear()
          Dialog({
            title: '改约时间',
            message: '工单服务时间已修改完成',
            confirmButtonColor: '#186AFF'
          }).then(() => {{ 
            this.$emit('submit', result)
          }})
        })
      },
    }
  }
</script>

<style lang="less" scoped>
  @import './style.less';
</style>