<template>
  <div class="order-page">
    <div class="order-container">
      <div class="order-card">
        <div class="card-title">服务进度</div>
        <div class="order-process" :class="{'active': processVisible}">
          <div class="process-item" v-for="(item, index) in logList" :key="index">
            <div class="desc" v-html="item.content"></div>
            <div class="info">{{ item.create_time }}</div>
          </div>
        </div>
        <div class="process-more-btn" v-if="logList.length > 1" @click="processVisible = !processVisible">
          <van-icon name="arrow-down" size="14" color="#9C9DAA"/>
        </div>
      </div>
      <div class="order-card">
        <div class="card-title">服务产品</div>
        <div class="card-info pro-info">
          <div class="photo">
            <img :src="info.product_photo" class="contain-img" alt="">
          </div>
          <div class="info">
            <div class="title">
              <div class="name">{{ info.product_category }}</div>
              <div class="tag flex-center" :class="{'orange': info.type == 5, 'blue': info.type != 5}">{{ info.type_title }}</div>
            </div>
            <template v-if="info.type == 1 || info.type == 6 || info.type == 7 || info.type == 8 || info.type == 9 || info.type == 10">
              <!-- 送货、保养、改气源、清洗 -->
              <div class="info-item">产品型号：{{ info.product_title || '型号暂未填写' }}</div>
              <div class="info-item">创建时间：{{ info.create_time }}</div>
            </template>
            <template v-if="info.type == 2 || info.type == 3 || info.type == 4">
              <!-- KDS、安装、前置 -->
              <div class="info-item">物流状态：
                <span class="datetime" v-if="info.logistics_status == 1">已到货</span>
                <span class="datetime" v-if="info.logistics_status == 2">未到货</span>
              </div>
              <div class="info-item">产品型号：{{ info.product_title || '型号暂未填写' }}</div>
            </template>
            <template v-if="info.type == 5">
              <div class="info-item">故障类型：<span class="datetime">{{ info.gz_title }}</span></div>
              <div class="info-item">产品型号：{{ info.product_title || '型号暂未填写' }}</div>
            </template>
          </div>
        </div>
        <div class="card-detail" v-if="info.remark">
          <div class="card-title">服务工单备注</div>
          <div class="card-tips">{{ info.remark }}</div>
        </div>
        <div class="card-detail" v-if="photoList.length > 0">
          <div class="card-title">备注图片</div>
          <div class="card-photos">
            <div class="photo" v-for="(item,index) in photoList" :key="index" @click="onPreview(index)">
              <img :src="item" class="fit-img" alt="">
            </div>
          </div>
        </div>
      </div>
      <div class="order-card">
        <div class="card-title">工单详情</div>
        <div class="cell-box van-hairline--top">
          <div class="cell">
            <div class="label">联系人</div>
            <div class="val">{{ info.username }}</div>
          </div>
          <div class="cell">
            <div class="label">联系电话</div>
            <div class="val">{{ info.mobile }}</div>
          </div>
          <div class="cell">
            <div class="label">服务地址</div>
            <div class="val">{{ info.address}}</div>
          </div>
          <div class="cell">
            <div class="label">服务时间</div>
            <div class="val">{{ info.appoint_time }}</div>
          </div>
          <div class="cell">
            <div class="label">工单单号</div>
            <div class="val">{{ info.order_sn }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="order-footer" v-if="info.status != 3">
      <div class="order-footer-main">
        <!-- sync = 1 表示同步订单，不需要显示按钮 -->
        <template v-if="info.is_sync == 0">
          <div class="order-btn flex-center van-hairline--surround" @click="onRemindOrder" v-if="info.status == 1 || info.status == 2">
            <span>催单</span>
          </div>
          <div class="order-btn flex-center van-hairline--surround" @click="showTimePopup" v-if="info.status == 1">
            <span>改约</span>
          </div>
        </template>
        <!-- <div class="order-btn flex-center van-hairline--surround" @click="evaluateVisible=true" v-if="info.status == 4 && info.is_comment == 1">
          <span>服务评价</span>
        </div> -->
        <div class="order-btn flex-center van-hairline--surround" @click="onDelete" v-if="info.status == 4">
          <span>删除工单</span>
        </div>
        <div class="order-btn flex-center van-hairline--surround" v-if="info.status == 0 || info.status == 1" @click="cancelVisible=true">
          <span>取消服务</span>
        </div>
        <div class="order-btn flex-center van-hairline--surround" v-if="info.status == 5" @click="reOrder">
          <span>重新下单</span>
        </div>
      </div>
    </div>
    <ServiceBtn />

    <!-- 取消订单 -->
    <MCancel 
      :id="id" 
      :visible="cancelVisible" 
      @close="closeCancel" 
      @cancel="onCancelOrder"/>

    <MEvaluate 
      :id="id" 
      :visible="evaluateVisible" 
      @close="closeEvaluate"
      @submit="changeEvaluate"
      :info="info"/>

    <!-- 改约 -->
    <ServiceTimePopup 
      ref="servTime"
      :visible="timeVisible" 
      :tid="id"
      @close="closeTimePopup" 
      @submit="choiceTimeSuccess"
    />
  </div>
</template>

<script>
  import ServiceBtn from '@/components/service-btn'
  import { Icon, ImagePreview, Dialog, Toast } from 'vant'
  import MCancel from './components/cancel'
  import MEvaluate from './components/evaluate'
  import ServiceTimePopup from '@/components/ServiceTimePopup'
  import { getOrderDetail, deleteOrder, remindOrder } from '@/api/order'

  export default {
    components: {
      ServiceBtn,
      MCancel,
      MEvaluate,
      [Icon.name]: Icon,
      ServiceTimePopup
    },
    data() {
      return {
        id: '',
        info: {},
        processVisible: false,
        cancelVisible: false,
        evaluateVisible: false,
        logList: [],
        photoList: [],
        timeVisible: false,
      }
    },
    created() {
      this.id = this.$route.params.id
      this.loadData()
    },
    methods: {
      loadData() {
        const toast = Toast.loading({
          message: '加载中...',
          forbidClick: true,
        })
        getOrderDetail({id: this.id}).then(res => {
          this.info = res.data.info
          this.photoList = res.data.info.photos || []
          this.logList = res.data.log_list || []
          toast.clear()
        })
      },
      onPreview(index) {
        ImagePreview({
          images: this.info.photos,
          startPosition: index
        })
      },
      /**
       * 删除工单
       */
      onDelete() {
        Dialog.confirm({
          title: '删除工单',
          message: '删除工单将无法找回，确认删除吗？',
          confirmButtonColor: '#186AFF'
        })
        .then(() => {
          const toast = Toast.loading({
            message: '加载中...',
            forbidClick: true,
          })
          deleteOrder({id: this.id}).then(() => {
            toast.clear()
            Dialog.alert({
              title: '删除成功',
              confirmButtonColor: '#186AFF'
            }).then(() => {
              this.$router.replace('/order')
            });
          })
        })
        .catch(() => {
          // on cancel
        });
      },
      /**
       * @description 催单
       */
      onRemindOrder() {
        const info = this.info
        const spTime = info.appoint_time.split(' ')
        // const time = spTime[1].split('-')
        const startTime = new Date(spTime[0].replace(/-/g, '/') + ' 00:00').getTime()
        const now = Date.now()
        if (startTime > now) {
          Dialog.alert({
            title: '催促工单',
            message: `您的服务预约在${ info.appoint_time }，请耐心等待，服务工程师会在上门前与您联系`,
            confirmButtonColor: '#186AFF'
          })
          return
        }
        const toast = Toast.loading({
          message: '处理中...',
          forbidClick: true,
        })
        remindOrder({id: this.id}).then(() => {
          toast.clear()
          Dialog.alert({
            title: '催促工单',
            message: '服务工单已催促，请耐心等待',
            confirmButtonColor: '#186AFF'
          }).then(() => {
            this.loadData()
          })
        })
      },
      /**
       * @description: 发表评论
       */      
      changeEvaluate() {
        this.loadData()
      },
      /**
       * 关闭取消弹窗
       */
      closeCancel() {
        this.cancelVisible = false
      },
      /**
       * @description: 取消订单
       */      
      onCancelOrder() {
        Toast.success('取消订单成功')
        this.cancelVisible = false
        this.loadData()
      },
      /**
       * 关闭取消弹窗
       */
      closeEvaluate() {
        this.evaluateVisible = false
      },
      /**
       * 重新下单
       */
      reOrder() {
        const router = [
          'sh',
          'kds',
          'qz',
          'az',
          'wx',
          'by',
          'qx',
          'gqy',
          'cg',
          'hx'
        ]
        this.$router.push('/service/' + router[this.info.type - 1])
      },
      showTimePopup() {
        this.$refs.servTime.setTime(this.info.appoint_time)
        this.timeVisible = true
      },
      choiceTimeSuccess() {
        this.closeTimePopup()
        this.loadData()
      },
      closeTimePopup() {
        this.timeVisible = false
      }
    },
  }
</script>

<style lang="less" scoped>
  @import './style.less';
</style>