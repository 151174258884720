<template>
  <div class="service-btn flex-center" @click="onService">
    <img src="~@/assets/images/icon/icon_service.svg" alt="">
  </div>
</template>

<script>
  import { Dialog, Toast } from 'vant'
  import wx from 'weixin-js-sdk'
  import { sendServiceMsg } from '@/api/common'
  export default {
    data() {
      return {

      }
    },
    methods: {
      onService() {
        Dialog.confirm({
          message: '是否前往咨询跳转老板服务公众号在线客服',
          confirmButtonColor: '#186AFF'
        })
        .then(() => {
          const toast = Toast.loading({
            message: '加载中...',
            forbidClick: true,
          })
          sendServiceMsg().then(() => {
            wx.closeWindow()
            // this.addServer()
            toast.clear()
          })
        })
        .catch(() => {
          // on cancel
        });
      }
    },
  }
</script>

<style lang="less" scoped>
  .service-btn {
    position: fixed;
    right: 4vw;
    bottom: calc(30vw + env(safe-area-inset-bottom));
    bottom: calc(30vw + constant(safe-area-inset-bottom));
    width: 19.466vw;
    height: 19.466vw;
    // background-color: #FFFFFF;
    // border-radius: 50%;
    cursor: pointer;
    // box-shadow: 0 0.8vw 1.7333vw 0 rgba(23,134,253,0.11);
    z-index: 20;
    img {
      width: 19.466vw;
      height: 19.466vw;
    }
  }
</style>